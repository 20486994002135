import "./dynamicBackground.scss";

import { BrowsableItem } from "~models/browsableItem";
import { ItemCollection } from "~models/itemCollection";
import { DOMHelper, IListComponent } from "~ui-lib";

import { MEABanner } from "../models/meaBanner";
import { RecoConsentBanner } from "../models/recoConsentBanner";
import { MEABannerView } from "../views/MEABannerView";

export enum DynamicBackgroundOverlayType {
  none = "",
  blur = "blur",
  gradient = "gradient",
  unitGradient = "unitGradient",
}

const defaultStaticRowTypes = ["mise_en_avant", "reco_consent_banner"];

const FALLBACK_IMAGE = require("~images/mea/fallbackBackground.jpg");

export class DynamicBackground {
  /************* HTML Elements **************/

  /**
   * HTML Background Element
   * Will be used to add a background image
   */
  private _backgroundElt: HTMLElement;
  /**
   * Keeping track of current background source
   * This will be used to cancel animation when new source is equals to old source
   */
  private _currentSource?: string;

  /**
   * HTML Overlay Element
   * Will be used to apply either a [Shadow] / [Blur] / [None] effect on top
   * of the background
   */
  private _overlayElt: HTMLElement;

  /************* Timer & Others **************/

  /**
   * Background change timeout
   */
  private _backgroundTimeout = 0;
  /**
   * Tracking number of time that a background source change
   * This will be used to avoid that a previously called background is applied when
   * a new one has been requested
   */
  private _opIDGenerator = 0;

  /************* Listeners **************/

  private _focusedViewUnregister?: () => void;
  private _swimlaneFocusedIdUnregister?: () => void;

  /**
   * Static source will be used on statics rows
   * or whole page if Dynamic Background is set to static
   *
   * /!\ WARNING: Static source won't be applied as a background.
   * Please use "source" to apply an initial background.
   */
  private _staticSource: string;

  /**
   * Statics rows type list
   *
   * Rows type that are part of this list will use  _staticSource as background if set
   * or fallbackBackground if not set.
   */
  private readonly _staticRowTypes: Set<string>;

  /**
   * Mark current Dyanmic Background as Static
   *
   * _staticRowTypes will be used as background if set on whole page
   * or fallbackBackground if not set.
   *
   * If set to static _staticRowTypes will be ignored and every rows
   * will have static backgrounds.
   */
  private _isStatic = false;

  constructor(
    root: HTMLElement,
    options: {
      source?: string;
      staticSource?: string;
      overlay: DynamicBackgroundOverlayType;
      staticRowTypes?: string[];
      isStatic?: boolean;
    }
  ) {
    const { source, overlay, staticRowTypes, staticSource, isStatic } = options;

    // Creating DOM elements
    this._backgroundElt = DOMHelper.createDivWithParent(root, "dynamicBackground");
    this._overlayElt = DOMHelper.createDivWithParent(this._backgroundElt, "dynamicBackgroundOverlay", overlay);

    // Handling static values
    this._staticSource = staticSource ?? FALLBACK_IMAGE;
    this._staticRowTypes = new Set(defaultStaticRowTypes);
    for (const rowType of staticRowTypes ?? []) {
      this._staticRowTypes.add(rowType);
    }
    this._isStatic = isStatic ?? false;

    /**
     * If page is set to static, applying static background
     * If not, using source background if set
     */
    this._backgroundElt.style.opacity = "1";
    if (this._isStatic) {
      this._setSrc(this._staticSource, this._opIDGenerator);
    } else if (source) {
      this._setSrc(source, this._opIDGenerator);
    } else {
      this._setSrc(FALLBACK_IMAGE, this._opIDGenerator);
    }
  }

  /************* OVERLAY **************/

  /**
   * Will update dynamic backgound overlay dynamically
   * @param overlay new overlay mode
   */
  setOverlay = (overlay: DynamicBackgroundOverlayType) => {
    this._overlayElt.classList.value = overlay;
  };

  /************* SOURCE **************/

  animSrc = (rawSrc: string) => {
    // Generating new operation id
    this._opIDGenerator++;
    const opId = this._opIDGenerator;

    /**
     * rawSrc is either a relative / absolute path
     * Using a temp image allow us to insure that source is always an absolute path
     * This will be usefull when comparing old and new image source
     */
    const tempImg = new Image();
    tempImg.src = rawSrc;
    const src = tempImg.src;

    // Cancelling old timeout (if existing)
    window.clearTimeout(this._backgroundTimeout);

    /**
     * New source is equal to old source
     * Skipping animation
     */
    if (this._currentSource === src) {
      // Resetting opacity
      this._backgroundElt.style.opacity = "1";
    } else {
      this._backgroundElt.style.opacity = "0";

      this._backgroundTimeout = window.setTimeout(() => {
        this._setSrc(src, opId);
      }, 800);
    }
  };

  private _setSrc = (src: string, opId: number) => {
    if (opId !== this._opIDGenerator) return;
    /**
     * Internal method to update background source
     * @param src: New background source
     */
    const setSrcElement = (src: string) => {
      // Saving current source
      if (opId === this._opIDGenerator) {
        this._currentSource = src;

        // Setting background element source
        this._backgroundElt.style.opacity = "1";
        this._backgroundElt.style.background = `url("${src}") no-repeat`;
        this._backgroundElt.style.backgroundSize = "100%";
      }
    };

    if (src === undefined || src === "") {
      setSrcElement(FALLBACK_IMAGE);
    } else {
      const bgImg = new Image();
      bgImg.onload = () => {
        bgImg.onload = null;
        setSrcElement(bgImg.src);
      };
      bgImg.onerror = () => {
        bgImg.onerror = null;
        if (opId === this._opIDGenerator) {
          if (this._staticSource === FALLBACK_IMAGE) {
            setSrcElement(this._staticSource);
          } else {
            const staticBgImg = new Image();
            staticBgImg.onload = () => {
              staticBgImg.onload = null;
              setSrcElement(staticBgImg.src);
            };

            staticBgImg.onerror = () => {
              staticBgImg.onerror = null;
              setSrcElement(FALLBACK_IMAGE);
            };

            staticBgImg.src = this._staticSource;
          }
        }
      };
      bgImg.src = src;
    }
  };

  /************* LISTENERS **************/

  /**
   * Keeping track of update background listener on ItemCollectionList
   * This will be used to release
   */
  private _updateBackgroundOnFocusChange = (list: IListComponent<ItemCollection | MEABanner | RecoConsentBanner>) => {
    if (this._isStatic === false) {
      const itemList = list.modelFromId(list.focusedId$.value);
      let browsableItem: BrowsableItem | undefined = undefined;
      if (itemList instanceof MEABanner) {
        browsableItem = itemList.item;
      } else {
        const swimlaneList = list.focusedView$.value?.delegate as IListComponent<BrowsableItem> | undefined;
        browsableItem = swimlaneList?.modelFromId(swimlaneList.focusedId$.value);
      }
      if (itemList !== undefined && browsableItem !== undefined && !this._staticRowTypes.has(itemList.type)) {
        // be carfull: backgroundSrc can be undefined in case of CGU items §that extend Item and no BrowsableItem)
        this.animSrc(
          (itemList instanceof MEABanner
            ? browsableItem?.getMEABannerImgUrl?.()
            : browsableItem?.getBackgroundImgUrl?.()) ?? ""
        );
      } else {
        this.animSrc(this._staticSource);
      }
    }
  };

  setItemCollectionList = (list: IListComponent<ItemCollection | MEABanner | RecoConsentBanner>) => {
    this._focusedViewUnregister = list.focusedView$.didChange((newV, oldV) => {
      // when the current swimlane focus change, unregister the focus listener from the previous swimlane & register a new one
      this._swimlaneFocusedIdUnregister?.();
      // update the background image (as we changed focus from one swimlane to another)
      this._updateBackgroundOnFocusChange(list);
      // and register focus changes on the new swimlane
      if (!(newV instanceof MEABannerView)) {
        this._swimlaneFocusedIdUnregister = (newV?.delegate as
          | IListComponent<BrowsableItem>
          | undefined)?.focusedId$.didChange(() => this._updateBackgroundOnFocusChange(list));
      }
    });
  };

  /************* VIEW **************/

  /**
   * Unregistering listener
   * Do not forgot to call this method when releasing DynamicBackground to free memory
   */
  onRelease() {
    this._focusedViewUnregister?.();
    this._swimlaneFocusedIdUnregister?.();
  }
}
