/* eslint-disable @typescript-eslint/no-explicit-any */
import jwt_decode, { JwtPayload } from "jwt-decode";
import { Observable, Observer } from "rxjs";
import { z } from "zod";

import { Artwork } from "~models/artwork";
import { BrowsableItem } from "~models/browsableItem";
import { Category } from "~models/category";
import { Channel } from "~models/channel";
import { Collection } from "~models/collection";
import { Event } from "~models/event";
import { Integrale } from "~models/integrale";
import { ItemCollection } from "~models/itemCollection";
import { Broadcast, Media } from "~models/media";
import { Metadata, Person, Rating } from "~models/metadata";
import { Program } from "~models/program";
import { Unit } from "~models/unit";
import { PlayButtonAction } from "~pages/program/programDesc";

import { Extrait } from "../models/extrait";
import { Flux } from "../models/flux";
import { Partner } from "../models/partner";
import { Region } from "../models/region";
import { Spritesheet } from "../models/spritesheet";
import { Tag } from "../models/tag";
import { getAdditionalProperties, parsePianoPageTypeEnum, PianoPageTypeName } from "../tools/analytics/piano";
import { CurrentOffSet } from "../tools/playerHistoryHelper";
import { ParseException } from "./exceptions";

export function parseHome(json: any): Observable<ItemCollection> {
  return new Observable((observer: Observer<ItemCollection>) => {
    if (json === null || json === undefined) {
      observer.error(new ParseException("Unexpected json: " + JSON.stringify(json)));
    } else {
      if (json instanceof Array) {
        json.forEach((item: any) => {
          const itemCollection = _parseCollection(item);
          if (itemCollection !== undefined) {
            observer.next(itemCollection);
          }
        });
      } else if (json.item) {
        // Specific parsing for event that is not formatted as expected
        let newJson = json;
        newJson = json.item;
        newJson.collections = json.collections;
        const itemCollection = _parseCollection(newJson);
        if (itemCollection !== undefined) {
          observer.next(itemCollection);
        }
      } else {
        const itemCollection = _parseCollection(json);
        if (itemCollection !== undefined) {
          observer.next(itemCollection);
        }
      }
    }

    observer.complete();
  });
}

export function parseBookmarks(json: unknown): CurrentOffSet[] {
  try {
    const offsets = z
      .array(
        z.object({ videoId: z.string(), progress: z.number() }).transform(data => {
          return {
            id: data.videoId,
            currentOffset: data.progress,
          };
        })
      )
      .parse(json);
    return offsets;
  } catch (e) {
    Log.app.error("Error parseBookmarks", e);
    return [];
  }
}

export function parseSearch(json: any): Observable<ItemCollection> {
  return new Observable((observer: Observer<ItemCollection>) => {
    if (json === null || json === undefined) {
      observer.error(new ParseException("Unexpected json: " + JSON.stringify(json)));
    } else {
      Object.entries(json).forEach(([key, value]) => {
        const newJson: any = {};
        newJson.collections = value;
        newJson.type = key;
        const itemCollection = _parseCollection(newJson);

        /**
         * When building a collection, every undhandled types
         * had been filtered out.
         *
         * This can lead the collection to be empty, resulting in an empty row.
         * We want to insure only rows with at least one element are visible.
         */
        if (itemCollection !== undefined && itemCollection.items.length > 0) {
          observer.next(itemCollection);
        }
      });
    }
    observer.complete();
  });
}

export function parseMenu(json: any): Observable<any> {
  return new Observable((observer: Observer<any>) => {
    if (json === null || json === undefined) {
      observer.error(new ParseException("Unexpected json: " + JSON.stringify(json)));
    } else {
      const jsonItems = json.items || [];
      jsonItems.forEach((item: any) => {
        item.id = item.slug + "_" + Math.random().toString(36).substr(2, 9);
        observer.next(item);
      });
    }

    observer.complete();
  });
}
export function parseTxtFavorite(txt: any): Observable<any> {
  return new Observable((observer: Observer<any>) => {
    if (txt === null || txt === undefined) {
      observer.error(new ParseException("Unexpected json: " + txt));
    } else {
      txt == "OK" ? observer.next(false) : observer.next(true);
    }
    observer.complete();
  });
}

export function parseItems(json: any): Observable<BrowsableItem> {
  return new Observable((observer: Observer<BrowsableItem>) => {
    if (json === null || json === undefined) {
      observer.error(new ParseException("Unexpected json: " + JSON.stringify(json)));
    } else {
      if (json instanceof Array) {
        json.forEach((item: any) => {
          const browsableItem = _parseItem(item);
          if (browsableItem !== undefined) {
            observer.next(browsableItem);
          }
        });
      } else {
        const browsableItem = _parseItem(json);
        if (browsableItem !== undefined) {
          observer.next(browsableItem);
        }
      }
    }

    observer.complete();
  });
}

export function parseJSON(json: any): Observable<JSON> {
  return new Observable((observer: Observer<JSON>) => {
    if (json === null || json === undefined) {
      observer.error(new ParseException("Unexpected json: " + JSON.stringify(json)));
    } else {
      observer.next(json);
    }

    observer.complete();
  });
}

export function parsePlayButtonAction(json: unknown): PlayButtonAction | undefined {
  if (typeof json === "object" && json !== null) {
    const actions = (json as Record<"actions", unknown>).actions;

    if (Array.isArray(actions)) {
      const response: PlayButtonAction[] = [];

      for (const action of actions) {
        if (
          typeof action === "object" &&
          action !== null &&
          typeof action.id === "number" &&
          typeof action.duration === "number" &&
          typeof action.label === "string" &&
          typeof action.episode_title === "string" &&
          action.type === "replay"
        ) {
          response.push(action);
        }
      }
      return response[0];
    }
  }

  return undefined;
}

/**
 * Parsing spritesheet object
 *
 * @param json raw spritesheet object
 * @returns
 */
export function parseSpritesheets(json: unknown): Spritesheet | undefined {
  if (Array.isArray(json)) {
    const spritesheet = json[0];

    if (
      spritesheet !== undefined &&
      typeof spritesheet?.columns === "number" &&
      typeof spritesheet?.height === "number" &&
      Array.isArray(spritesheet?.images) &&
      typeof spritesheet?.interval === "number" &&
      typeof spritesheet?.lines === "number" &&
      typeof spritesheet?.width === "number"
    ) {
      const images: string[] = [];
      for (const image of spritesheet.images) {
        if (typeof image === "string") {
          images.push(image);
        }
      }

      return {
        ...spritesheet,
        images,
      };
    }
  }

  return undefined;
}

type customJwtPayload = JwtPayload & { userId: string };

export function parseToken(json: any): Observable<JSON> {
  return new Observable((observer: Observer<JSON>) => {
    if (json === null || json === undefined) {
      observer.error(new ParseException("Unexpected json: " + JSON.stringify(json)));
    } else {
      const tokenDecoded = jwt_decode<customJwtPayload>(json.access_token);
      json.tokenDecoded = tokenDecoded;
      observer.next(json);
    }

    observer.complete();
  });
}

export type ParsedAdBreaks = {
  prerollAdBreak: unknown[] | undefined;
  midrollAdBreak: Record<string, unknown> | undefined;
  postrollAdBreak: unknown[] | undefined;
};

export function parseAds(json: any): Observable<ParsedAdBreaks> {
  //console.log("parseAds :: ", json);

  let prerollAdBreak: unknown[] | undefined = undefined;
  let midrollAdBreak: Record<string, unknown> | undefined = undefined;
  let postrollAdBreak: unknown[] | undefined = undefined;

  return new Observable((observer: Observer<ParsedAdBreaks>) => {
    if (json === null || json === undefined) {
      observer.error(new ParseException("Unexpected xml: " + JSON.stringify(json)));
    } else if (json["vmap:AdBreak"] && json["vmap:AdBreak"].length) {
      const ads = json["vmap:AdBreak"];
      ads.forEach((adBreak: { timeOffset: string }) => {
        if (_validAdBreak(adBreak)) {
          switch (adBreak.timeOffset) {
            case "start":
              if (prerollAdBreak == undefined) {
                prerollAdBreak = [];
              }
              prerollAdBreak.push(adBreak);
              break;
            case "end":
              if (postrollAdBreak == undefined) {
                postrollAdBreak = [];
              }
              postrollAdBreak.push(adBreak);
              break;
            default: {
              if (midrollAdBreak == undefined) {
                midrollAdBreak = {};
              }
              // Hack to avoid insertion of pauseroll with timeOffset at 00:00:00
              // midroll with offset 0 making the ad controller to go in a infinite loop
              const timeOffset: number = _elapsedTime(adBreak.timeOffset);
              if (timeOffset > 0) {
                midrollAdBreak[_elapsedTime(adBreak.timeOffset)] = adBreak;
              }
              break;
            }
          }
        }
      });

      observer.next({
        prerollAdBreak,
        midrollAdBreak,
        postrollAdBreak,
      });
    }

    observer.complete();
  });
}

function _validAdBreak(adBreak: any): any {
  Log.ads.error(adBreak.timeOffset);
  if (adBreak["vmap:AdSource"] && adBreak["vmap:AdSource"].length) {
    adBreak = adBreak["vmap:AdSource"][0];
    if (adBreak["vmap:VASTAdData"] && adBreak["vmap:VASTAdData"].length) {
      adBreak = adBreak["vmap:VASTAdData"][0];
      if (adBreak["VAST"] && adBreak["VAST"].length) {
        adBreak = adBreak["VAST"][0];
        if (adBreak["Ad"] && adBreak["Ad"].length) {
          Log.ads.log("Nb ads : " + adBreak["Ad"].length);
          return true;
        } else {
          Log.ads.error("VAST but no Ad");
          return false;
        }
      }
      return false;
    }
    return false;
  }
  return false;
}

const _elapsedTime = function (timeFormatted: string): number {
  let elapsedTime = 0;

  const timeArray = timeFormatted.split(":");
  const hoursForSconds = parseInt(timeArray[0]) * 3600;
  const minForSconds = parseInt(timeArray[1]) * 60;
  const seconds = parseInt(timeArray[2]);

  elapsedTime = hoursForSconds + minForSconds + seconds;
  return elapsedTime;
};

/**
 * Directs create separate swmilanes in model
 * create 3 types of swimlanes: playlist_channel, playlist_event, playlist_extern
 * @param json
 */
export function parseDirects(json: any): Observable<ItemCollection> {
  return new Observable((observer: Observer<ItemCollection>) => {
    if (json === null || json === undefined) {
      observer.error(new ParseException("Unexpected json: " + JSON.stringify(json)));
    } else if (json.items) {
      if (json.items) {
        const itemsChannels: Array<BrowsableItem> = [];
        const itemCollectionsEventObj: Record<string, ItemCollection> = {};
        const itemsExterns: Array<BrowsableItem> = [];
        const extras: any = {};

        // Parse images
        const artworks: Array<Artwork> = [];

        //playlist_channel
        const itemCollectionChannels = new ItemCollection(
          "playlist_channel" + "_" + Math.random().toString(36).substr(2, 9),
          "playlist_channel",
          "En direct",
          undefined,
          itemsChannels,
          artworks,
          extras,
          typeof json.sponsored === "boolean" ? json.sponsored : false
        );

        //playlist_channel
        const itemCollectionExterns = new ItemCollection(
          "playlist_extern" + "_" + Math.random().toString(36).substr(2, 9),
          "playlist_extern",
          "En direct sur france.tv",
          undefined,
          itemsExterns,
          artworks,
          extras,
          typeof json.sponsored === "boolean" ? json.sponsored : false
        );

        json.items.forEach((item: any) => {
          if (_isValidJson(item["broadcast_channel"]))
            if (item["broadcast_channel"] == "externe") {
              if (_isValidJson(item["event"]) && item["event"].type == "event" && _isValidJson(item["event"].id)) {
                // Parse Items and create collection according to event types found
                if (!itemCollectionsEventObj[item.event.id]) {
                  const items: Array<BrowsableItem> = [];
                  //playlist_event
                  itemCollectionsEventObj[item.event.id] = new ItemCollection(
                    "playlist_event" + "_" + Math.random().toString(36).substr(2, 9),
                    "playlist_event",
                    item.event.label || item.event.title,
                    undefined,
                    items,
                    artworks,
                    extras,
                    typeof json.sponsored === "boolean" ? json.sponsored : false
                  );
                }
                itemCollectionsEventObj[item.event.id].items.push(_parseItem(item));
              } else if (!_isValidJson(item["event"])) {
                const parsedItem = _parseItem(item);
                if (parsedItem !== undefined) {
                  itemsExterns.push(parsedItem);
                }
              }
            } else {
              const parsedItem = _parseItem(item);
              if (parsedItem !== undefined) {
                itemsChannels.push(parsedItem);
              }
            }
        });

        // Send playlist channels first
        if (itemCollectionChannels.items.length > 0) {
          observer.next(itemCollectionChannels);
        }

        // Sort Playlist event by number of items descendant
        let itemsCollectionsEvent: Array<ItemCollection> = [];
        Object.entries(itemCollectionsEventObj).forEach(([, value]) => {
          itemsCollectionsEvent.push(value);
        });

        itemsCollectionsEvent = itemsCollectionsEvent.sort((a, b) => (a.items > b.items ? -1 : 1));
        itemsCollectionsEvent.forEach(itemsCollectionEvent => {
          if (itemsCollectionEvent.items.length > 0) {
            observer.next(itemsCollectionEvent);
          }
        });

        // Send playlist externs at the end
        if (itemCollectionExterns.items.length > 0) {
          observer.next(itemCollectionExterns);
        }
      }
    }

    observer.complete();
  });
}

/**
 * Item Collection can be :
 * mise_en_avant, playlist_video, playlist_video_auto, playlist_mixed, playlist_program, playlist_program_auto
 */
function _parseCollection(json: any): ItemCollection | undefined {
  const items: Array<BrowsableItem> = [];
  const extras: any = {};

  const jsonItems = json.items || json.collections || [];

  jsonItems.forEach((item: any) => {
    const children = _parseItem(item);
    if (children !== undefined) {
      if (children instanceof Flux) {
        /**
         * Flux Type should be only show in specific rows
         */
        if (json.type === "mise_en_avant") {
          items.push(children);
        } else {
          // Do not push flux on other rows
        }
      } else {
        /**
         * Any other type
         */
        items.push(children);
      }
    }
  });

  // Specific item not based on root json -_-
  if (json.item && !json.items) {
    json = json.item;
  }

  if (json.type !== undefined) {
    /**
     * Handling special collection rows
     */
    switch (json.type) {
      case "playlist_video_auto": {
        /**
         * playlist_video_auto can be pushed automatically in France 3 Region page
         * when the back office does not specify any content for a region
         * This row should be handled as a `playlist_video` row.
         */
        json.type = "playlist_video";
        break;
      }
      case "playlist_program_auto": {
        /**
         * playlist_program_auto can be pushed automatically in France 3 Region page
         * when the back office does not specify any content for a region
         * This row should be handled as a `playlist_program` row.
         */
        json.type = "playlist_program";
        break;
      }
      default: {
        // Don't do anything
        break;
      }
    }
  }

  // Parse images
  const artworks: Array<Artwork> = [];
  if (json.images) {
    json.images.forEach((image: any) => {
      const atwks = _parseArworks(image, json.id);
      atwks.forEach((artwork: Artwork) => {
        artworks.push(artwork);
      });
    });
  }

  const itemId = json.id || json.type + "_" + Math.random().toString(36).substr(2, 9);

  // extras?
  Object.keys(json).map(key => {
    if (_isValidJson(json[key])) {
      if (
        [
          "ads_blocked",
          "downloadable",
          "marker",
          "marker_piano",
          "subtitle",
          "episode_title",
          "integral_counter",
          "si_id",
          "episode",
          "season",
          "channel",
          "channel_path",
          "channel_url",
          "partner",
          "partner_path",
          "headline_title",
          "label_edito",
          "url_complete", // for event
          // "tag_path" , // may be needed
        ].includes(key)
      ) {
        extras[key] = json[key];
      }
    }
  });

  // Improved Collection Parser for empty program and mes videos recommendations, categorie, collection without item
  // We just want to remove swimlane without items
  if (
    items.length === 0 &&
    json.type !== "recommendations" &&
    json.type !== "categorie" &&
    json.type !== "program" &&
    json.type !== "event" &&
    json.type !== "collection" &&
    json.type !== "tag"
  ) {
    return undefined;
  }
  const itemCollection = new ItemCollection(
    itemId,
    json.type,
    json.label || json.title,
    json.description || json.synopsis,
    items,
    artworks,
    extras,
    typeof json.sponsored === "boolean" ? json.sponsored : false
  );
  for (const item in itemCollection.items) itemCollection.items[item].itemCollection = itemCollection;
  return itemCollection;
}

/**
 * BrowsableItem can be:
 * collection, program, unitaire, integrale, categorie, event
 */
function _parseItem(json: any): BrowsableItem | undefined {
  const debug: any = {};

  const extras: any = {};

  // Parse images
  const artworks: Array<Artwork> = [];
  if (json.images) {
    json.images.forEach((image: any) => {
      const atwks = _parseArworks(image, json.id);
      atwks.forEach((artwork: Artwork) => {
        artworks.push(artwork);
      });
    });
  }

  // Parse Metadatas
  let metadata: Metadata | null | undefined = null;

  // Parse media
  let media: Media | null | undefined = null;
  if (
    (_isValidJson(json.begin_date) && _isValidJson(json.end_date)) ||
    (_isValidJson(json.broadcast_begin_date) && _isValidJson(json.broadcast_end_date))
  ) {
    media = _parseMedia(json);
    debug.media = media;
  }

  let item: BrowsableItem | undefined = undefined;

  /* we handle "bande-annonce" item as "extrait" !!!!!! https://francetv.atlassian.net/browse/SMARTTV-278 */
  /* as well for "resume" and "bonus" https://francetv.atlassian.net/browse/SMARTTV-1218 */
  if (json.type === "bande-annonce" || json.type === "resume" || json.type === "bonus") {
    json.type = "extrait";
  }

  // Parse type to define object
  if (_isValidJson(json.items || json.collections)) {
    item = _parseCollection(json);
  } else {
    switch (json.type) {
      case "program":
        debug.program = json;
        // Parse other keys that can be needed (but I don't know yet so it is temporary)
        Object.keys(json).map(key => {
          if (_isValidJson(json[key])) {
            if (
              [
                "ads_blocked",
                "downloadable",
                "program_path",
                "marker",
                "marker_piano",
                "integral_counter",
                "channel",
                "partner",
                "headline_title",
                "label_edito",
                "number_of_episodes",
              ].includes(key)
            ) {
              extras[key] = json[key];
            }
          }
        });
        item = new Program(
          json.id,
          json.type,
          json.label || json.title,
          json.description || json.synopsis,
          artworks,
          extras,
          typeof json.sponsored === "boolean" ? json.sponsored : false
        );
        break;
      case "collection":
        debug.collection = json;
        Object.keys(json).map(key => {
          if (_isValidJson(json[key])) {
            if (["marker", "marker_piano", "subtitle", "integral_counter", "headline_title"].includes(key)) {
              extras[key] = json[key];
            }
          }
        });
        item = new Collection(
          json.id,
          json.type,
          json.label || json.title,
          json.description,
          artworks,
          extras,
          typeof json.sponsored === "boolean" ? json.sponsored : false
        );
        break;
      case "categorie":
        debug.categorie = json;
        Object.keys(json).map(key => {
          if (_isValidJson(json[key])) {
            if (["marker", "marker_piano", "url_complete", "integral_counter", "headline_title"].includes(key)) {
              extras[key] = json[key];
            }
          }
        });
        item = new Category(
          json.id,
          json.type,
          json.label || json.title,
          json.description || json.synopsis,
          artworks,
          extras
        );
        break;
      case "event":
        debug.event = json;
        Object.keys(json).map(key => {
          if (_isValidJson(json[key])) {
            if (
              [
                "marker",
                "marker_piano",
                "url_complete",
                "integral_counter",
                "call_to_action_url",
                "call_to_action",
                "headline_title",
              ].includes(key)
            ) {
              extras[key] = json[key];
            }
          }
        });
        item = new Event(
          json.id,
          json.type,
          json.label || json.title,
          json.description || json.synopsis,
          artworks,
          extras,
          typeof json.sponsored === "boolean" ? json.sponsored : false
        );
        break;
      case "channel":
        debug.channel = json;
        item = _parseChannel(json);
        break;
      case "region":
        debug.region = json;
        item = _parseRegion(json);
        break;
      case "integrale":
        debug.integrale = json;
        // eslint-disable-next-line no-case-declarations
        metadata = _parseMetadata(json);
        debug.metadata = metadata;
        Object.keys(json).map(key => {
          if (_isValidJson(json[key])) {
            if (key == "channel") {
              extras[key] = _parseChannel(json[key]);
            } else if (key == "partner") {
              extras[key] = _parsePartner(json[key]);
            } else if (key == "program") {
              extras[key] = _parseItem(json[key]);
            } else if (key == "category") {
              extras[key] = _parseItem(json[key]);
            } else if (key == "event") {
              extras[key] = _parseItem(json[key]);
            } else if (
              [
                "ads_blocked",
                "downloadable",
                "marker",
                "marker_piano",
                "subtitle",
                "episode_title",
                "integral_counter",
                "si_id",
                "episode",
                "season",
                "headline_title",
                "label_edito",
                "transactions",
              ].includes(key)
            ) {
              extras[key] = json[key];
            }
          }
        });
        item = new Integrale(
          json.id,
          json.type,
          json.label || json.title,
          json.description || json.synopsis,
          artworks,
          metadata,
          extras,
          media,
          json.login,
          typeof json.sponsored === "boolean" ? json.sponsored : false
        );
        break;
      case "unitaire":
        debug.unitaire = json;
        // eslint-disable-next-line no-case-declarations
        metadata = _parseMetadata(json);
        debug.metadata = metadata;
        Object.keys(json).map(key => {
          if (_isValidJson(json[key])) {
            if (key == "channel") {
              extras[key] = _parseChannel(json[key]);
            } else if (key == "partner") {
              extras[key] = _parsePartner(json[key]);
            } else if (key == "program") {
              extras[key] = _parseItem(json[key]);
            } else if (key == "category") {
              extras[key] = _parseItem(json[key]);
            } else if (key == "event") {
              extras[key] = _parseItem(json[key]);
            } else if (
              [
                "ads_blocked",
                "downloadable",
                "marker",
                "marker_piano",
                "subtitle",
                "episode_title",
                "integral_counter",
                "si_id",
                "episode",
                "season",
                "headline_title",
                "label_edito",
                "transactions",
              ].includes(key)
            ) {
              extras[key] = json[key];
            }
          }
        });
        item = new Unit(
          json.id,
          json.type,
          json.label || json.title,
          json.description || json.synopsis,
          artworks,
          metadata,
          extras,
          media,
          json.login,
          typeof json.sponsored === "boolean" ? json.sponsored : false
        );
        break;
      case "extrait":
        debug.extrait = json;
        // eslint-disable-next-line no-case-declarations
        metadata = _parseMetadata(json);
        debug.metadata = metadata;
        Object.keys(json).map(key => {
          if (_isValidJson(json[key])) {
            if (key == "channel") {
              extras[key] = _parseChannel(json[key]);
            } else if (key == "partner") {
              extras[key] = _parsePartner(json[key]);
            } else if (key == "program") {
              extras[key] = _parseItem(json[key]);
            } else if (key == "category") {
              extras[key] = _parseItem(json[key]);
            } else if (key == "event") {
              extras[key] = _parseItem(json[key]);
            } else if (
              [
                "ads_blocked",
                "downloadable",
                "marker",
                "marker_piano",
                "subtitle",
                "episode_title",
                "integral_counter",
                "si_id",
                "episode",
                "season",
                "headline_title",
                "label_edito",
                "transactions",
              ].includes(key)
            ) {
              extras[key] = json[key];
            }
          }
        });
        item = new Extrait(
          json.id,
          json.type,
          json.label || json.title,
          json.description || json.synopsis,
          artworks,
          metadata,
          extras,
          media,
          json.login
        );
        break;
      case "flux":
        debug.flux = json;
        // eslint-disable-next-line no-case-declarations
        metadata = _parseMetadata(json);
        debug.metadata = metadata;
        Object.keys(json).map(key => {
          if (_isValidJson(json[key])) {
            if (key == "channel") {
              extras[key] = _parseChannel(json[key]);
            } else if (key == "partner") {
              extras[key] = _parsePartner(json[key]);
            } else if (key == "program") {
              extras[key] = _parseItem(json[key]);
            } else if (key == "category") {
              extras[key] = _parseItem(json[key]);
            } else if (key == "event") {
              extras[key] = _parseItem(json[key]);
            } else if (
              [
                "ads_blocked",
                "downloadable",
                "marker",
                "marker_piano",
                "subtitle",
                "episode_title",
                "integral_counter",
                "si_id",
                "episode",
                "season",
                "headline_title",
                "label_edito",
              ].includes(key)
            ) {
              extras[key] = json[key];
            }
          }
        });
        item = new Flux(
          json.id,
          json.type,
          json.label || json.title,
          json.description || json.synopsis,
          artworks,
          metadata,
          extras,
          media,
          json.login
        );
        break;
      case "tag":
        Object.keys(json).map(key => {
          if (_isValidJson(json[key])) {
            if (
              [
                "marker",
                "marker_piano",
                "url_complete",
                "integral_counter",
                "call_to_action_url",
                "call_to_action",
                "headline_title",
                "tag_path",
              ].includes(key)
            ) {
              extras[key] = json[key];
            }
          }
        });
        item = new Tag(
          json.id,
          json.type,
          json.label || json.title,
          json.description || json.synopsis,
          artworks,
          extras
        );
        break;
      case "partner":
        item = _parsePartner(json);
        break;
      default:
        break;
    }
  }

  //consoleLog("debug", debug);
  if (item === undefined) {
    Log.app.warn("_parseItem unknown object type : '" + json.type + "'");
    Log.app.warn(json);
  }
  return item;
}

/**
 * Images from item
 */
function _parseArworks(json: unknown, itemId: string): Array<Artwork> {
  const artworks: Array<Artwork> = [];

  const parseResult = z.object({ type: z.string(), urls: z.record(z.string(), z.string()) }).safeParse(json);
  if (parseResult.success) {
    const imagesUrls = parseResult.data.urls;
    for (const widthKey of Object.keys(imagesUrls)) {
      const width: string = widthKey.replace("w:", "");
      const artwork = new Artwork(itemId + "#" + width, width, parseResult.data.urls[widthKey], parseResult.data.type);
      artworks.push(artwork);
    }
  }

  return artworks;
}

/**
 * Channel from item
 */
function _parseChannel(json: any): Channel {
  const extras: any = {};

  // Parse images
  const artworks: Array<Artwork> = [];

  if (json.images) {
    json.images.forEach((image: any) => {
      const atwks = _parseArworks(image, json.id);
      atwks.forEach((artwork: Artwork) => {
        artworks.push(artwork);
      });
    });
  }

  Object.keys(json).map(key => {
    if (_isValidJson(json[key]) && ["marker", "marker_piano", "channel_path", "channel_url", "si_id"].includes(key)) {
      extras[key] = json[key];
    }
  });

  return new Channel(
    json.type + "_" + json.id,
    json.type,
    json.label,
    json.description || json.synopsis,
    artworks,
    extras
  );
}

/**
 * Partner from item
 */
function _parsePartner(json: any): Partner {
  const extras: any = {};

  // Parse images
  const artworks: Array<Artwork> = [];

  if (json.images) {
    json.images.forEach((image: any) => {
      const atwks = _parseArworks(image, json.id);
      atwks.forEach((artwork: Artwork) => {
        artworks.push(artwork);
      });
    });
  }

  Object.keys(json).map(key => {
    if (
      _isValidJson(json[key]) &&
      ["marker", "marker_piano", "partner_path", "si_id", "headline_title"].includes(key)
    ) {
      extras[key] = json[key];
    }
  });

  return new Partner(
    json.type + "_" + json.id,
    json.type,
    json.label,
    json.description || json.synopsis,
    artworks,
    extras
  );
}

/**
 * Region from item
 */
function _parseRegion(json: any): Region {
  const extras: any = {};

  // Parse images
  const artworks: Array<Artwork> = [];

  if (json.images) {
    json.images.forEach((image: any) => {
      const atwks = _parseArworks(image, json.id);
      atwks.forEach((artwork: Artwork) => {
        artworks.push(artwork);
      });
    });
  }

  Object.keys(json).map(key => {
    if (_isValidJson(json[key]) && ["marker", "marker_piano", "region_path"].includes(key)) {
      extras[key] = json[key];
    }
  });

  return new Region(
    json.type + "_" + json.id,
    json.type,
    json.label,
    json.description || json.synopsis,
    artworks,
    extras
  );
}

/**
 * Metadatas from item for casting, duration, rating, parental level etc...
 */
function _parseMetadata(json: any): Metadata {
  const extras: any = {};

  // Duration
  const duration: number = json.duration ? json.duration : 0;

  // Production year
  const prodYear: number = json.production_year ? json.production_year : null;

  // Casting
  const casting: Array<Person> = [];

  // Rating
  const rating: Rating =
    json.rating_csa && json.rating_csa_code
      ? new Rating(json.rating_csa, json.rating_csa_code, 0)
      : new Rating("", "", 0);

  // Extras
  Object.keys(json).map(key => {
    if (_isValidJson(json[key])) {
      if (key == "casting" || key == "characters" || key == "director" || key == "presenter" || key == "producer") {
        _parsePerson(json[key], key).forEach((person: Person) => {
          casting.push(person);
        });
      } else if (
        ["is_audio_descripted", "is_live", "is_multi_lingual", "is_recommended", "is_subtitled"].includes(key)
      ) {
        extras[key] = json[key];
      }
    }
  });

  return new Metadata(extras, duration, casting, prodYear, rating);
}

/**
 * Media from item for broadcast and video infos
 */
function _parseMedia(json: any): Media {
  let broadcast: Broadcast | null = null;
  let beginDate: Date | null = null;
  let endDate: Date | null = null;

  const extras: any = {};
  const extrasBroadcast: any = {};

  if (_isValidJson(json.broadcast_begin_date) && _isValidJson(json.broadcast_end_date)) {
    if (_isValidJson(json.broadcast_channel)) {
      extrasBroadcast["broadcast_channel"] = json.broadcast_channel;
    }

    broadcast = new Broadcast(
      new Date(json.broadcast_begin_date * 1000),
      new Date(json.broadcast_end_date * 1000),
      extrasBroadcast
    );
  }

  if (_isValidJson(json.begin_date) && _isValidJson(json.end_date)) {
    beginDate = new Date(json.begin_date * 1000);
    endDate = new Date(json.end_date * 1000);
  }

  return new Media(extras, broadcast, beginDate, endDate);
}

/**
 * Casting from item: charracters, presenters, director...
 */
function _parsePerson(json: any, type: string): Array<Person> {
  const persons: Array<Person> = [];

  const splitted = json.split(", ");
  if (splitted) {
    splitted.forEach((title: string) => {
      persons.push(new Person(title, type));
    });
  }

  return persons;
}

function _isValidJson(value: any): boolean {
  return value !== null && value !== undefined && value !== "";
}

type MarkerPianoParsed = {
  additional_properties: Record<string, string>;
  contextual_properties: Record<string, unknown> | undefined;
};

/**
 * Parse `marker_piano` object from extra parameter
 * AND insuring that contextual_properties is correctly typed
 *
 * This method insures we have a marker_piano of MarkerPianoParsed shape
 * OR `undefined` if parsing has failed
 * @param extras
 * @returns MarkerPianoParsed | undefined
 */
export const parseMarkerPianoPageDisplay = (
  extras: unknown
):
  | (Omit<MarkerPianoParsed, "contextual_properties"> & {
      contextual_properties: { page: string; page_type: PianoPageTypeName };
    })
  | undefined => {
  const markerPiano = parseMarkerPiano(extras);

  if (markerPiano !== undefined) {
    // Getting Contextual Properties
    const pageParams = parseMarkerPianoPageParams(markerPiano);

    if (pageParams === undefined) {
      // Contextual properties has failed to be parsed
      return undefined;
    } else {
      // Marker Piano
      return {
        additional_properties: getAdditionalProperties(markerPiano?.additional_properties),
        contextual_properties: pageParams,
      };
    }
  } else {
    // Error during the parsing of Marker Piano
    return undefined;
  }
};

/**
 * Parse `marker_piano` object from extra parameter
 *
 * This method insures we have a marker_piano of MarkerPianoParsed shape
 * OR `undefined` if parsing has failed
 * @param extras
 * @returns MarkerPianoParsed | undefined
 */
export const parseMarkerPiano = (extras: unknown): MarkerPianoParsed | undefined => {
  if (typeof extras === "object" && extras !== null) {
    const markerPiano = (extras as {
      marker_piano:
        | Record<"additional_properties" | "contextual_properties", Record<string, unknown> | undefined | null>
        | undefined;
    }).marker_piano;

    return markerPiano === undefined
      ? undefined
      : {
          additional_properties: getAdditionalProperties(markerPiano.additional_properties),
          contextual_properties: markerPiano.contextual_properties ?? undefined,
        };
  } else {
    return undefined;
  }
};

/**
 * Parse page & page type fields from Marker Piano Object
 *
 * @param markerPiano - `extas.marker_piano` record
 * @returns Page & Page Type or undefined if parsing failed
 */
const parseMarkerPianoPageParams = (
  markerPiano:
    | Record<"additional_properties" | "contextual_properties", Record<string, unknown> | null | undefined>
    | undefined
): { page: string; page_type: PianoPageTypeName } | undefined => {
  if (markerPiano !== undefined) {
    if (typeof markerPiano.contextual_properties?.page === "string") {
      const page = markerPiano.contextual_properties.page;

      const rawPageType = parsePianoPageTypeEnum(markerPiano.contextual_properties?.page_type);
      if (rawPageType !== undefined) {
        return {
          page,
          page_type: rawPageType,
        };
      }
    }
  }

  return undefined;
};
