import "./playerMagnetoPage.scss";

import { DOMHelper, IPage, Keys, platform, PlatformType, View } from "../../../bud-lite-tv/src/lib";
import { Plugin } from "../../datas/plugin";
import { TizenCWHelper } from "../../datas/TizenCWManager";
import { navigationStack } from "../../main";
import { ItemCollection } from "../../models/itemCollection";
import { PlayableItem } from "../../models/playableItem";
import { getOriginPageParams } from "../../tools/analytics/piano";
import { Spideo } from "../../tools/analytics/spideo";
import { Didomi } from "../../tools/cmp/didomi";
import { PlayHistoryHelper } from "../../tools/playerHistoryHelper";

const _playerVersionElement = DOMHelper.createDivWithParent(
  document.getElementById("mainContent"),
  "PlayerMagnetoVersion"
);

let _magnetoVersion = "";

export const magnetoVersion = () => {
  return _magnetoVersion;
};

interface PlayerMagnetoOptions {
  publicId?: string;
  userLoggedIn?: boolean;
  autostart?: boolean;
  platform?: string;
  consent?: {
    ad?: string;
    estat?: "optin" | "optout" | "exempt";
    npaw?: "optin" | "exempted";
    nielsen?: "consented" | "undefined";
    freewheel?: boolean;
    adUserId?: string;
    pubUserId?: string;
    recoUserId?: string;
  };
  env?: {
    device?: string;
    firmware?: string;
    connection_type?: string;
    app_version?: string;
    showAd?: boolean;
  };
  showAd?: boolean;
  midroll?: boolean;
  startTimecode?: number;
  tracking?: {
    page?: string;
    pageType?: string;
    pageProvenance?: string;
    pageProvenanceType?: string;
    zoneProvenance?: string;
    positionVignette?: string | number;
    playProvenance?: PlayProvenance;
  };
  next?: boolean;
  diffusion?: {
    mode?: "tunnel" | "tunnel_first";
    position?: string | number;
    length?: string | number;
  };
  comingNext?: {
    program?: string;
    title?: string;
    preTitle?: string;
  };
  logo?: string;
  debug?: boolean;
}

interface PlayerInitData {
  src: string;
  config: PlayerMagnetoOptions;
}

type PlayProvenanceTunnel = "tunnel_auto" | "tunnel_next" | "tunnel_comingnext";
type PlayProvenance = PlayProvenanceTunnel | "recommendation" | "rejouer" | "reessayer" | "zapette" | "relancer";

type PlayerMagnetoEvent =
  | "error"
  | "next"
  | "settingsOpened"
  | "settingsClosed"
  | "trackListOpened"
  | "trackListClosed"
  | "tunnelOpened"
  | "tunnelClosed"
  | "spritesheetsOpened"
  | "spritesheetsClosed"
  | "playerClose"
  // | "new_player"
  | "pause"
  // | "canplay"
  // | "canplaythrough"
  // | "waiting"
  // | "zappingOpened"
  // | "zappingClicked"
  // | "userVolumeChange"
  // | "userPlaybackRateChange"
  // | "userQualityChanged"
  // | "userTextTrackChanged"
  // | "userTunnelActivated"
  // | "blur"
  // | "comingNextDisplayed"
  // | "enterpictureinpicture"
  // | "leavepictureinpicture"
  // | "new_player"
  // | "new_video"
  // | "pause "
  | "play"
  // | "playing"
  // | "renderer_ready"
  | "seeked"
  // | "seeking"
  // | "stalled"
  // | "stopped"
  // | "timeshiftingBackToLiveRequested"
  // | "timeupdate"
  // | "userAudioTrackChanged"
  // | "userMute"
  // | "userVolumeChanged"
  | "video_start"
  // | "watchedTimeReached"
  // | "zappingClicked"
  // | "zappingOpened"
  | "onSlotStarted"
  | "onSlotEnded";

interface PlayerMagnetoType {
  load: (video: PlayerInitData) => void;
  play: () => void;
  pause: () => void;
  stop: () => void;
  // seek: (position: number) => void;
  // forward: () => void;
  // rewind: () => void;
  // next: () => void;
  // mute: (value: boolean) => void;
  // volume: (value: number) => void;
  // fullscreen: (active: boolean) => void;
  on: (eventName: PlayerMagnetoEvent, callback: (eventName: string, payload: unknown) => void) => void;
  off: (eventName: PlayerMagnetoEvent, callback: (eventName: string, payload: unknown) => void) => void;
  getCurrentTime: () => number;
  // getDuration: () => number;
  // getCurrentProgress: () => number;
  // getPlayerContainer: () => HTMLElement;
  // getLayer: () => HTMLElement;
  // setReco: (recommendations: any[]) => void;
  dispose: (emptyContainer: boolean) => void;
  // isAdServerReachable: () => boolean;
  toJSON: () => {
    version: string;
    name: string;
  };
}

type Tunnel = {
  currentPosition: number;
  readonly contents: PlayableItem[];
  nextItem: PlayableItem | undefined;
  startTimecode: number;
};

export class PlayerMagnetoPage extends View implements IPage {
  private _Magnetoscope = (window as any).magnetoscope;
  private _player?: PlayerMagnetoType | undefined;
  private _currentItem: PlayableItem;
  private readonly _progressInSec: number;
  private _indexInSlider: number | undefined;
  private _isPlaying = true;
  private _isLive: boolean;
  private _handleBackButton = true;

  constructor(item: PlayableItem, index?: number) {
    super(DOMHelper.createDivWithParent(null, "PlayerMagnetoPage", "playerMagnetoPage"));
    this._currentItem = item;
    this._isLive = item.metadata.extras?.is_live ? true : false;
    this._indexInSlider = index != undefined ? index + 1 : undefined;

    // manage progress
    const user = Plugin.getInstance().user;
    // don't fetch the progress if it is a live or user is not logged in
    if (this._isLive === false && user.isActive()) {
      const duration = item.metadata.duration;
      this._progressInSec = PlayHistoryHelper.getCurrentOffset(item) ?? 0;
      this._progressInSec = Math.max(0, Math.min(duration, this._progressInSec));
      if (this._progressInSec > 0.96 * duration) {
        this._progressInSec = 0;
      }
    } else {
      this._progressInSec = 0;
    }
  }

  private _getConfigPublicId = () => {
    // TODO: verify is publicId   or user.id
    return Plugin.getInstance().user.id;
  };

  private _getConfigConsent = () => {
    return {
      estat: Didomi.isVendorAllowedToTrack("mediametri-Npn8xCFG") ? "optin" : "exempt",
      npaw: Didomi.isVendorAllowedToTrack("youbora-YdPrzZLh") ? "optin" : "exempted",
      nielsen: Didomi.isVendorAllowedToTrack("mediametri-Npn8xCFG") ? "consented" : "undefined",
    } as const;
  };

  private _getConfigEnv = () => {
    const device = (() => {
      switch (platform.type) {
        case PlatformType.tizen:
          return "samsung";
        case PlatformType.webos:
          return "lg";
        case PlatformType.hisense:
          return "hisense";
        case PlatformType.philips:
          return "philips";
        default:
          return "lg";
      }
    })();

    return {
      device: device,
      app_version: __APP_VERSION__,
    };
  };

  private _getConfigPlatform = () => {
    return "smarttv";
  };

  private _onError = (eventName: string, payload: unknown) => {
    Log.player.error("[MAGNETO] error", payload);
  };

  private _onPlayerClose = () => {
    Log.player.info("[MAGNETO] onPlayerClose");
    this._saveProgress();
    navigationStack.removePage(this);
  };

  private _onVideoStart = () => {
    Log.player.info("[MAGNETO] _onVideoStart");
    this._sendSpideoEvent("startPlaying");
  };

  private _onSeeked = () => {
    Log.player.info("[MAGNETO] _onSeeked");
    this._sendSpideoEvent("seek");
  };

  private _onPlay = () => {
    Log.player.info("[MAGNETO] _onPlay");
    this._isPlaying = true;
  };

  private _onPause = () => {
    Log.player.info("[MAGNETO] _onPause");
    this._sendSpideoEvent("pause");
    this._isPlaying = false;
  };

  private _onSlotStarted = () => {
    this._isPlaying = false;
  };

  private _onSlotEnded = () => {
    this._isPlaying = true;
  };

  private _playingEventInterval = setInterval(() => {
    if (this._isPlaying) {
      this._sendSpideoEvent("playing");
    }
  }, 60 * 1000);

  init = () => {
    Log.player.log("[MAGNETO] init");
    try {
      const initMagnetoOptions: { debug: boolean; platform: string; webservices?: { gateway?: string } } = {
        debug: true,
        platform: this._getConfigPlatform(),
      };
      if (__BACKEND_TARGET__ === "pre") {
        initMagnetoOptions.webservices = {
          gateway: "https://k7.ftven-preprod.fr/videos/",
        };
      }

      this._player = new this._Magnetoscope(this.rootElement, initMagnetoOptions) as PlayerMagnetoType;
      _magnetoVersion = this._player?.toJSON?.()?.version ?? "";
      if (__IS_RELEASE__ === false) {
        _playerVersionElement.innerText = _magnetoVersion;
      }
    } catch (e: unknown) {
      Log.player.log("[MAGNETO] init, cannot create new magneto player.");
      // TODO: maybe display a popup and remove the page
      return;
    }

    this._player.on("error", this._onError);

    this._player.on("playerClose", this._onPlayerClose);

    this._player.on("video_start", this._onVideoStart);

    this._player.on("seeked", this._onSeeked);

    this._player.on("pause", this._onPause);

    this._player.on("play", this._onPlay);

    this._player.on("onSlotStarted", this._onSlotStarted);

    this._player.on("onSlotEnded", this._onSlotEnded);

    // Setting listeners
    const openedEvents: PlayerMagnetoEvent[] = [
      "trackListOpened",
      "tunnelOpened",
      "spritesheetsOpened",
      "settingsOpened",
    ];
    const closedEvents: PlayerMagnetoEvent[] = [
      "trackListClosed",
      "tunnelClosed",
      "spritesheetsClosed",
      "settingsClosed",
    ];

    for (const event of openedEvents) {
      this._player.on(event, () => {
        this._setHandleBackButton(false);
      });
    }

    for (const event of closedEvents) {
      this._player.on(event, () => {
        this._setHandleBackButton(true);
      });
    }

    if (this._isLive === false) {
      Plugin.getInstance()
        .fetchNextEpisodes(this._currentItem)
        .toPromise()
        .then((value: unknown[]) => {
          const nextEpisodesItemCollection = value[0];
          if (nextEpisodesItemCollection instanceof ItemCollection && nextEpisodesItemCollection.items.length > 0) {
            // ajout dde l'item au début du tunnel
            nextEpisodesItemCollection.items.unshift(this._currentItem);
            const tunnel: Tunnel = {
              currentPosition: 0,
              contents: nextEpisodesItemCollection.items,
              nextItem: nextEpisodesItemCollection.items[1],
              startTimecode: this._progressInSec ?? 0,
            };
            this._player?.on("next", (eventName, payload) => {
              const playProvenance = (() => {
                if (payload === "button") {
                  return "tunnel_next";
                } else if (payload === "comingNext") {
                  return "tunnel_comingnext";
                } else {
                  return "tunnel_auto";
                }
              })();

              this._saveProgress();
              if (tunnel.currentPosition + 1 < tunnel.contents.length) {
                tunnel.currentPosition++;
                this._currentItem = tunnel.contents[tunnel.currentPosition];
                if (tunnel.currentPosition + 1 < tunnel.contents.length) {
                  tunnel.nextItem = tunnel.contents[tunnel.currentPosition + 1];
                  tunnel.startTimecode = 0;
                } else {
                  tunnel.nextItem = undefined;
                }
              }
              this._loadVideo(playProvenance, tunnel);
            });

            // loading first video
            this._loadVideo(undefined, tunnel);
          } else {
            // tunnel is empty
            this._loadVideo(undefined, undefined);
          }
        })
        .catch((error: unknown) => {
          // err loading initial video without tunnel
          Log.player.error("fetchNextEpisodes failed", error);
          this._loadVideo(undefined, undefined);
        });
    } else {
      // currentItem is a live, loading without tunnel
      this._loadVideo(undefined, undefined);
    }
  };

  private _setHandleBackButton(handleBackButton: boolean) {
    this._handleBackButton = handleBackButton;
  }

  private _loadVideo = (playProvenance: PlayProvenance | undefined, tunnel: Tunnel | undefined) => {
    if (this._player === undefined) {
      Log.app.error("[MAGNETO] player is undefined, init was not called or page is released!");
      return;
    }
    Log.player.log("[MAGNETO] loading video...", this._currentItem.id);

    const originPageParams = getOriginPageParams();

    // if the playableItem is a "live" and not "externe", it means that is a "live channel", the si_id have to be taken from extras.channel.si_id
    let si_id = undefined;
    if (
      this._currentItem.metadata?.extras?.is_live &&
      this._currentItem.media?.broadcast?.extras?.broadcast_channel &&
      this._currentItem.media.broadcast.extras.broadcast_channel !== "externe"
    ) {
      si_id = this._currentItem.extras?.channel?.extras?.si_id || this._currentItem?.extras?.partner?.extras?.si_id;
    } else {
      si_id = this._currentItem.extras?.si_id;
    }

    if (typeof si_id !== "string") {
      Log.app.error("[MAGNETO] si_id is not a string", this._currentItem);
      // return;
    }

    const isArteContent = this._currentItem.media?.broadcast?.extras?.broadcast_channel === "arte";

    let shouldShowAd = !this._currentItem.extras.ads_blocked;
    if (isArteContent) {
      shouldShowAd = this._isLive ? false : !this._currentItem.extras.ads_blocked;
    }

    const videoOptions: PlayerInitData = {
      src: si_id,
      config: {
        autostart: true,
        publicId: this._getConfigPublicId(),
        userLoggedIn: Plugin.getInstance().user.isActive(),
        showAd: shouldShowAd,
        midroll: isArteContent && !this._isLive ? false : undefined,
        startTimecode: tunnel === undefined ? this._progressInSec : tunnel.startTimecode,
        platform: this._getConfigPlatform(),
        tracking: {
          // TODO: check what to do
          page: "player",
          pageType: "player",
          pageProvenance: originPageParams.page ?? undefined,
          pageProvenanceType: originPageParams.pageType ?? undefined,
          // zoneProvenance: this._item?.itemCollection?.type ?? undefined,
          // positionVignette: this._indexInSlider,
          playProvenance,
        },
        consent: this._getConfigConsent(),
        env: this._getConfigEnv(),
        debug: true,
      },
    };

    if (this._isLive) {
      const channelLogo: string | undefined = this._currentItem.extras?.channel?.getLogoImgUrl();
      if (channelLogo !== undefined) {
        videoOptions.config.logo = channelLogo;
      }
    }

    if (tunnel !== undefined) {
      videoOptions.config.diffusion = {
        mode: tunnel.currentPosition === 0 ? "tunnel_first" : "tunnel",
        position: tunnel.currentPosition + 1,
        length: tunnel.contents.length,
      };
      if (tunnel.nextItem !== undefined) {
        videoOptions.config.next = true;
        videoOptions.config.comingNext = {
          program: tunnel.nextItem.extras?.program?.title ?? "", // TODO: verfiy how to get the title when item is from an event and don't have program
          title: tunnel.nextItem.extras?.episode_title ?? "",
          preTitle: "",
        };
      } else {
        videoOptions.config.next = false;
      }
    } else {
      // no tunnel
      videoOptions.config.next = false;
    }

    this._sendSpideoEvent("play");
    this._player.load(videoOptions);
  };

  onRelease = () => {
    Log.player.log("[MAGNETO] Destroying player...");
    this._sendSpideoEvent("stop");
    this._player?.dispose(false);
    this._player = undefined;
    window.clearInterval(this._playingEventInterval);
  };

  private _saveProgress = () => {
    const user = Plugin.getInstance().user;
    if (this._isLive === false && user.isActive() && this._player !== undefined) {
      const currenTime = this._player.getCurrentTime();
      Plugin.getInstance()
        .putHits(user, this._currentItem, currenTime)
        .subscribe(
          value => {
            // Here use it to get content searched
            Log.api.log("[putHits] next !", value);
          },
          error => {
            // Here use it to trigger and display an error
            Log.api.log("[putHits] Error !", error);
          },
          () => {
            Log.api.log("[putHits] Complete !");
          }
        );
      if (platform.type === PlatformType.tizen) {
        if (
          currenTime >= this._currentItem.metadata.duration * 0.98 ||
          currenTime < this._currentItem.metadata.duration * 0.02
        ) {
          // Deletes video from Tizen CW if content watched for more than
          // defined offset
          TizenCWHelper.deleteItem(this._currentItem);
        } else {
          // Adds video to Tizen CW with current progress
          TizenCWHelper.addItem(this._currentItem, currenTime);
        }
      }
      PlayHistoryHelper.updateOffset(this._currentItem, currenTime);
    }
  };

  // These key handlers are here for testing. Magneto will implement it on its side.
  onNav = (key: Keys): boolean => {
    Log.player.log(key);
    switch (key) {
      case Keys.play:
        this._player?.play();
        return true;
      case Keys.playPause:
        this._isPlaying ? this._player?.pause() : this._player?.play();
        return true;
      case Keys.pause:
        this._player?.pause();
        return true;
      case Keys.stop:
        this._onPlayerClose();
        return true;
      case Keys.back:
        if (this._handleBackButton) {
          // saveProgress should not be called in onRelease, because onRelease will be called after the onShown of the videoTile
          // the progressBar of the videoTile will have then the correct value
          this._saveProgress();
          // return false, navigationStack will remove the page
          return false;
        } else {
          // return true to stop propagation of the event, backButton is handled by magnetoPlayer
          return true;
        }
    }
    return false;
  };

  private _sendSpideoEvent = (eventName: "playing" | "stop" | "pause" | "startPlaying" | "seek" | "play") => {
    if (!this._isLive) {
      Log.player.info("[SPIDEO] sending the event " + eventName);
      if (eventName === "playing" || eventName === "stop" || eventName === "pause") {
        const duration = Math.max(0, this._currentItem.metadata.duration);
        const offset = Math.max(0, Math.min(duration, Math.floor(this._player?.getCurrentTime() ?? 0)));
        const progressPercent = Math.ceil(duration <= 0 || offset <= 0 ? 0 : (offset / duration) * 100);
        Spideo.sendSpideoEvent({
          eventName,
          videoId: this._currentItem.id,
          timeViewed: offset,
          completion: progressPercent,
        });
      } else {
        Spideo.sendSpideoEvent({ eventName, videoId: this._currentItem.id });
      }
    }
  };
}
